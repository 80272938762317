import React, {ChangeEvent, FormEvent} from "react";
import { Redirect } from "react-router-dom";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

interface LoginDetails {
    user: string,
    password: string
}

export class LoginPage extends React.Component<any>{
    state = {
        login: {
            user: '',
            password: '',
            Session: ''
        },
        LoginResult: {
            Error: 100,
            Message: '',
            Session: '',
            UserName: '',
            F1: '',
            Poa: 'false',
            Cra: 'false'
        },
        hasErrors: false,
        authenticated : false
    };

    render() {
        if(this.authenticated()) {
            return <Redirect to={{pathname: '/Approvals'}}/>;
        } else {
            var err_message = this.state.LoginResult.Message;
            return (
                <div className='container-fluid'>
                    <div className="card col-lg-4 col-md-4 col-sm-6 offset-lg-4 offset-md-4 offset-sm-3">
                        <form onSubmit={this.handleSubmit}>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <p className="col-12" style={centerStyle}>&nbsp;</p>
                                    <h3 className="col-12" style={centerStyle}>Approvals Login</h3>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    {this.state.login.user.length > 0 && <small id="emailHelp" className="form-text text-muted">E-mail</small>}
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="E-mail*" onChange={this.handleUser} value={this.state.login.user} required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    {this.state.login.password.length > 0  && <small id="passwordHelp" className="form-text text-muted">Your password</small>}
                                    <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password*" onChange={this.handlePass} value={this.state.login.password} minLength={3} maxLength={20} required />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <button type="submit" className="btn btn-primary btn-lg col-12"><strong>Login</strong></button>
                                </div>
                            </div>
                            {(this.state.LoginResult.Error !== 100) && <div className="text-danger"><p>Error : {err_message}</p></div>}
                            {(this.state.LoginResult.Error === 200) && <div className="text-success"><p>Success: You Are Logged In!</p></div>}
                        </form>
                    </div>
                </div>
            )
        }
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }

    private authResponse = (authResponse: boolean) => {
        this.props.authCallback(authResponse);
    }

    private handleUser = (event: ChangeEvent<HTMLInputElement>) => {
        const login:LoginDetails = {...this.state.login, user: event.target.value};
        this.setState({login});
    };

    private handlePass = (event: ChangeEvent<HTMLInputElement>) => {
        const login:LoginDetails = {...this.state.login, password: event.target.value};
        this.setState({login});
    };

    private handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if(!this.authenticated()) {
            if(this.state.login.user !== '' && this.state.login.password !== '') {
                var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/Login";
                var payload = {
                    Usr: this.state.login.user,
                    Pass: this.state.login.password
                }
                
                fetch(tmp_url, {
                    method: "POST",
                    body: JSON.stringify(payload)
                })
                .then(res => res.json())
                .then(res => {
                    this.setState({ LoginResult: res });
                })
                .then(() => {
                    if(this.state.LoginResult.Session.length > 10) {
                        sessionStorage.setItem('session', this.state.LoginResult.Session);
                        sessionStorage.setItem('userName', this.state.login.user.substring(0, this.state.login.user.indexOf('@')));
                        sessionStorage.setItem('poa', this.state.LoginResult.Poa);
                        sessionStorage.setItem('cra', this.state.LoginResult.Cra);
                    }
                })
                .then(() => {
                    this.authResponse(this.authenticated());
                    if(this.authenticated()) {
                        //window.location.reload(false)
                    }
                })
                .catch(() => this.setState({ hasErrors: true }));
            } else {
                alert('Please enter all Fields');
            }
        }
    };
}

export default LoginPage;