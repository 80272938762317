import React from "react";
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import { version } from "../../../package.json";

type IsAuthed = {
    authorised: boolean
}

export class Footer extends React.Component<IsAuthed>  {
    static defaultProps = {
        authorised: false
    }

    state = {
        IsAuthed: this.props.authorised,
        Result: {
            Error: 100,
            Message: '',
            Package: [{
                app_version: version,
                usr_version: version,
                updateAvail: false
            }]
        }
    }

    // on componend mount, retrieve version
    componentDidMount() {
        window.scrollTo(0, 0);
        this.retrieveVersion();
    }

    render() {
        var { IsAuthed } = this.state;
        var { app_version, updateAvail } = this.state.Result.Package[0];

        return  (
            <>
            <div id={(IsAuthed || this.authenticated()) ? "FtA" : "Ft"} className='footer'>
                <div className="float-left"><a href="https://www.acclaimgroup.co.nz">&nbsp;Acclaimgroup</a> <small className="pull-right text-dark">v{app_version} Beta</small></div>
                {updateAvail !== false && (this.state.IsAuthed || this.authenticated()) &&
                <div className="btn btn-sm btn-success float-right" onClick={this.handleUpdate}>
                    <FontAwesomeIcon icon={faArrowAltCircleUp} fixedWidth /> Update Available
                </div>
                }
                {updateAvail === false && (this.state.IsAuthed || this.authenticated()) &&
                <div className="btn btn-sm btn-light float-right" onClick={this.retrieveVersion}>
                    <FontAwesomeIcon icon={faArrowAltCircleUp} fixedWidth /> Check Update
                </div>
                }
            </div>
            </>
        )
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10) : false;
    }

    private retrieveVersion = () => {
        if (this.authenticated()) {
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/VersionSelect";
            var payload = {
                Session: sessionStorage.getItem('session')
            }
            
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({Result: res});
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private handleUpdate = () => {
        if (this.authenticated()) {
            if(this.state.Result.Package[0].updateAvail) {
                var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/VersionUpdate";
                var payload = {
                    Session: sessionStorage.getItem('session')
                }
                
                fetch(tmp_url, {
                    method: "POST",
                    body: JSON.stringify(payload)
                })
                .then(res => res.json())
                .then(res => {
                    this.setState({Result: res});
                })
                .catch(() => this.setState({ hasErrors: true }));
            }
            window.location.reload(false);
        }
    }
};

export default Footer;