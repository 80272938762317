import React from "react";
import { Redirect } from "react-router-dom";

export class Logout extends React.Component<any> {
    state = { LogoutResult: {Error: 100, Message: '' }, hasErrors: false, logged_out: false };

    componentDidUpdate() {
        const { logged_out } = this.state;
        if(logged_out) {
            sessionStorage.removeItem('session');
            sessionStorage.removeItem('userName');
            sessionStorage.removeItem('poa');
            sessionStorage.removeItem('cra');
        }
    };

    render() {
        const { logged_out } = this.state;
        if(logged_out) {
            return <Redirect to={{pathname: '/'}}/>;
        } else {
            return (
                <div className='container-fluid'>
                    <div className="card col-lg-4 col-md-4 col-sm-6 offset-lg-4 offset-md-4 offset-sm-3">
                        <small>&nbsp;</small>
                        <label className="text-center">Please confirm Logout</label>
                        <button className="btn btn-danger" onClick={this.logout}>Confirm Logout{this.state.LogoutResult.Message}</button>
                        <div>&nbsp;</div>
                    </div>
                </div>
            )
        }
    }

    private authenticated = () => {
		return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10) : false;
	}

    private authResponse = (authResponse: boolean) => {
        this.props.authCallback(authResponse);
    }

    private logout = () => {
        if(this.authenticated()) {
            const Token = sessionStorage.getItem('session');
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/Logout";
            var payload = {
                Session: Token
            }
            
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ LogoutResult: res });
                this.setState({logged_out: true});
            })
            //.then(() => window.location.reload(false))
            .then(() => this.authResponse(this.authenticated()))
            .catch(() => this.setState({ hasErrors: true }));
        } else {
            this.authResponse(this.authenticated())
            this.setState({logged_out: true});
            //window.location.reload(false);
        }
    }
}

export default Logout;