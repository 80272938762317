import React from "react";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}


export class HomePage extends React.Component {
    render() {
        return (
            <>
            <div className='container-fluid'>
                <div className='jumbotron'>
                    <h3 style={centerStyle}>Welcome to the Approvals System</h3>
                </div>
            </div>
            </>
        );
    }
}

export default HomePage;