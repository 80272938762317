import React, { Component } from "react";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}


class TestArea extends Component {
    state = {
        maxCall: 2,
        lastId: 0,
        hasErrors: false,
        pen: {id: 0, trans: 0, filename: '', origional_name: ''}
    };

    componentDidMount() {
        var tmp_url = "http://localhost/ApprovalSystem/File/Info/"+this.state.lastId+"/"+this.state.maxCall;
        fetch(tmp_url, {
            method: "GET"
        })
        .then(res => res.json())
        .then(res => this.setState({ pen: res.files[0] }))
        .catch(() => this.setState({ hasErrors: true }));
    }

    render() {return (
        <>
            <div className="jumbotron">
                <p style={centerStyle}>This is an API secret test area.</p>

                <div className="text-warning">{this.state.hasErrors}</div><br />

                <p>Clean Display Below:</p>
                <p><b>ID:</b> {JSON.stringify(this.state.pen.id)}</p>
                <p><b>Transaction:</b> {JSON.stringify(this.state.pen.trans)}</p>
                <p><b>File Name:</b> {JSON.stringify(this.state.pen.filename)}</p>
                <p><b>File Path:</b> {JSON.stringify(this.state.pen.origional_name)}</p>
            </div>
        </>
    )}
};

export default TestArea;