import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCog, faCloud, faSearch } from '@fortawesome/free-solid-svg-icons';

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

type UserCardItem = {
    card : {
        id: string,
        name: string,
        has_login: boolean,
        active: boolean
    }
}

class UserCard extends Component<UserCardItem> {
    static defaultProps = {
        card: {
            id: "-1",
            name: 'Error - No Users Found',
            has_login: false,
            active: false
        }
    }

    render() {
        var { id, active, has_login } = this.props.card;
        return(
            <>
            <li id={id} className="list-group-item">
                <div className="row">
                    <div className="col-10 col-lg-11">{this.props.card.name}</div>
                    {active &&
                    <div className="col-2 col-lg-1 ">
                    <NavLink className="btn btn-sm btn-default border AuthCard float-right" to={'/Approvals/USR/'+id} >
                        <FontAwesomeIcon className="text-info" icon={faCog} fixedWidth /><br />
                        <div className="sub_txt">Settings</div>
                    </NavLink>
                    </div>
                    }
                    <div className="col-12">
                        <div className={(active) ? "text-success" : "text-muted"}><FontAwesomeIcon icon={faUser} fixedWidth /> {(active) ? "Active Staff" : "Inactive Staff"}</div>
                        <div className={(has_login) ? "text-success" : "text-muted"}><FontAwesomeIcon icon={faCloud} fixedWidth /> {(has_login) ? "Has Login" : "No Login"}</div>
                    </div>
                </div>
            </li>
            </>
        )
    }
}

export class USRMngPage extends Component {
    state = {
        result: {
            Error: 100,
            Message: 'Success', 
            Package: [{ 
                id: -1,
                name: "",
                has_login: false,
                active: false
            }],
            Item_Count: 1
        },
        hasErrors: false,
        search: {
            Search1: '',
            Search2: ''
        },
        paging: {
            Number: 1,
            Limit: 10,
            pageMin: 1,
            pageMax: 1
        },
        maxPages: 1
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.retrieve_user_list();
    }

    render() {
        var { paging, result, search } = this.state;
        
        var search_bar =
            <>
            <div className="col-12 col-lg-4">
                <div className="input-group">
                    <input id="Search1" type="text" className="form-control text-muted" placeholder="STAFF#" aria-label="searchStaffNo" aria-describedby="basic-addon1" onKeyUp={this.add_search} />
                    <input id="Search2" type="text" className="form-control text-muted" placeholder="STAFF NAME" aria-label="searchStaffName" aria-describedby="basic-addon1" onKeyUp={this.add_search} />
                    <div className="input-group-append" onClick={this.search_value}>
                        <span id="basic-addon1" className={(search.Search1.length > 0 || search.Search2.length > 0) ? "input-group-text text-white" : "input-group-text"} style={search.Search1.length > 0 ? {background: "#77C777"} : {}}><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                    </div>
                </div>
            </div>
            </>
        ;
        var pageSelect=[];
        for(var k = paging.pageMin; k <= paging.pageMax; k++) {
            if(paging.Number === k)  {
                pageSelect.push(<li key={'pg_'+k.toString()} className="page-item"><button className="page-link text-white" style={{background: "#77C777"}}>{k}</button></li>);
            } else {
                pageSelect.push(<li key={'pg_'+k.toString()} className="page-item"><button className="page-link text-muted" onClick={this.setPage} value={k}>{k}</button></li>);
            }
        }

        var elements=[];
        for(var i = 0; i < result.Package.length; i++){
            // push the component to elements!
            var item = {
                id: result.Package[i].id.toString(),
                name: result.Package[i].name,
                has_login: result.Package[i].has_login,
                active: result.Package[i].active
            };
            elements.push(<><UserCard key={item.id} card={item} /><small>&nbsp;</small></>);
        }

        var pagenation = 
            <>
                <nav aria-label="col-12 Page navigation">
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <button className="page-link text-muted" onClick={this.setPage} value={paging.Number-1} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </button>
                        </li>
                        {pageSelect}
                        <li className="page-item">
                            <button className="page-link text-muted" onClick={this.setPage} value={paging.Number+1} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </>
        ;

        return (
            <>
                <div className='jumbotron' style={centerStyle}>
                <h3>User Managment</h3>
                </div>
                <ul className="list-group">
                    {search_bar}<small>&nbsp;</small>
                    {pagenation}
                    <small>&nbsp;</small>
                    {elements}
                    {this.state.hasErrors && <li><div className="row text-red">Errors</div></li>}
                    {pagenation}
                </ul>
            </>
        );
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }

    private setPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        var selectedValue = Number(e.currentTarget.value);
        var { maxPages } = this.state;

        var pageNo = Math.max(selectedValue, 1);
        pageNo = Math.min(pageNo, maxPages);
        
        //this.setState({ paging: {Number: pageNo, Limit: this.state.paging.Limit}});
        this.setPaginationRange(pageNo, this.state.paging.Limit);
        window.scrollTo(0, 0);
        this.retrieve_user_list(pageNo, this.state.paging.Limit);
    }

    private setPaginationRange = (pageNumber: number = this.state.paging.Number, pageLimit: number = this.state.paging.Limit) => {
        var { paging, maxPages } = this.state;
        var barSize = 3;
        if (pageNumber <= barSize) {
            paging.pageMin = 1;
            paging.pageMax = (barSize * 2);
        }

        if ((pageNumber >= (barSize)) && (pageNumber <= (maxPages - (barSize)))) {
            paging.pageMin = pageNumber - (barSize);
            paging.pageMax = pageNumber + barSize;
        }

        if (pageNumber >= (maxPages - barSize)) {
            paging.pageMin = maxPages - (barSize * 2);
            paging.pageMax = maxPages;
        }
        paging.pageMin = Math.max(1, paging.pageMin);
        this.setState({paging: { Number: pageNumber, Limit: pageLimit, pageMin : paging.pageMin, pageMax: paging.pageMax }});
    }  

    private add_search = (e: React.KeyboardEvent<any>) => {
        e.preventDefault();
        const search1_value = document.getElementById("Search1") as HTMLInputElement;
        const search2_value = document.getElementById("Search2") as HTMLInputElement;
        if (this.authenticated()) {
            if ( e.key === 'Enter' ){
                this.search_value();
            } else {
                this.setState({
                    search: {
                        Search1: search1_value.value, 
                        Search2: search2_value.value
                    }
                });
            }
        }
    }

    private search_value = () => {
        this.retrieve_user_list();
    }

    private retrieve_user_list = (number: number = this.state.paging.Number, limit: number = this.state.paging.Limit) => {
        if(this.authenticated()) {
            var paging = {Number: number, Limit: limit}
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/USR/List";
            var payload = {
                Session: sessionStorage.getItem('session'),
                Search: this.state.search,
                Paging: paging
            }
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .then(() => {
                this.setState({ maxPages: Math.ceil(Math.max(1, this.state.result.Item_Count)/Math.max(1, this.state.paging.Limit)) });
            })
            .then(() => {
                this.setPaginationRange();
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }
}

export default USRMngPage;