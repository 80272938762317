import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronUp, faChevronDown, faChevronLeft, faChevronRight, faTasks, faChild, faUsersCog } from '@fortawesome/free-solid-svg-icons'
import './MenuBar.css';


export class MenuBar extends Component  {
    state = {
        menuExpand: true
    }
    
    render () {
       /*  const activeStyle = { color: '#FAFAFA', textShadow: '0px 0px 1px #FAFAFA' } */
        const activeStyle = { color: '#77C777', textShadow: '0px 0px 1px #FAFAFA' }
        const menuStyleSm = { display: 'inline' }
        const menuStyleLg = { display: 'inline', paddingLeft: '5px' }
        return (
            <>
                <div className='menuBarD d-none d-lg-block'> {/* Large Menu */}
                    <nav>
                        <div className="setCursor" onClick={this.handleDisp}>
                            <div className="text-overflow">
                                {this.state.menuExpand ? <FontAwesomeIcon style={menuStyleLg} icon={faChevronLeft} fixedWidth /> : <FontAwesomeIcon style={menuStyleLg} icon={faChevronRight} fixedWidth /> }
                                {this.state.menuExpand && <div style={menuStyleLg}>Menu</div>}
                            </div>
                        </div>
                        {(sessionStorage.getItem('poa') === 'true') && 
                        <div className="border-top">
                            <NavLink to='/Approvals/POA' activeStyle={activeStyle}>
                                <div className="text-overflow">
                                    <FontAwesomeIcon icon={faTasks} fixedWidth />
                                    {this.state.menuExpand && <div style={menuStyleLg}>PO Approval</div>}
                                </div>
                            </NavLink>
                        </div>}
                        {(sessionStorage.getItem('cra') === 'true') &&
                        <div className="border-top">
                            <NavLink to='/Approvals/CRA' activeStyle={activeStyle}>
                                <div className="text-overflow">
                                    <FontAwesomeIcon icon={faChild} fixedWidth />
                                    {this.state.menuExpand && <div style={menuStyleLg}>CR Approval</div>}
                                </div>
                            </NavLink>
                        </div>}
                        <div className="border-top">
                            <NavLink to='/Approvals/USR' activeStyle={activeStyle}>
                                <div className="text-overflow">
                                    <FontAwesomeIcon icon={faUsersCog} fixedWidth />
                                    {this.state.menuExpand && <div style={menuStyleLg}>User Settings</div>}
                                </div>
                            </NavLink>
                        </div>
                    </nav>
                </div>
                <div className='menuBarM d-block d-lg-none'> {/* Small Menu */}
                    <div className="container-fluid">
                        <nav className="row flex-row flex-nowrap">
                            <div className="col-3 text-center setCursor" onClick={this.handleDisp}>
                                {this.state.menuExpand ? <div><FontAwesomeIcon icon={faChevronDown} size="2x" fixedWidth /></div> : <div><FontAwesomeIcon icon={faChevronUp} size="2x" fixedWidth /></div>}
                                {this.state.menuExpand && <div style={menuStyleSm}>Menu</div>}
                            </div>
                            {(sessionStorage.getItem('poa') === 'true') &&
                            <div className="col-3 border-left text-center">
                                <NavLink to='/Approvals/POA' activeStyle={activeStyle}>
                                    <div><FontAwesomeIcon icon={faTasks} size="2x" fixedWidth /></div>
                                    {this.state.menuExpand && <div style={menuStyleSm}>PO Approval</div>}
                                </NavLink>
                            </div>}
                            {(sessionStorage.getItem('cra') === 'true') &&
                            <div className="col-3 border-left text-center">
                                <NavLink to='/Approvals/CRA' activeStyle={activeStyle}>
                                    <div><FontAwesomeIcon icon={faChild} size="2x" fixedWidth /></div>
                                    {this.state.menuExpand && <div style={menuStyleSm}>CR Approval</div>}
                                </NavLink>
                            </div>}
                            <div className="col-3 border-left text-center">
                                <NavLink to='/Approvals/USR' activeStyle={activeStyle}>
                                    <div><FontAwesomeIcon icon={faUsersCog} size="2x" fixedWidth /></div>
                                    {this.state.menuExpand && <div style={menuStyleSm}>User Settings</div>}
                                </NavLink>
                            </div>
                        </nav>
                    </div>
                </div>
            </>
        )
    }
    private handleDisp = () => {
        const { menuExpand } = this.state;
        this.setState({menuExpand: !menuExpand});
    };

};

export default MenuBar;