import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardList, faThumbsUp, faStop, faPlay, faLink, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import '../auth_list/AuthList.css';

import { Redirect } from "react-router-dom";

import { DetailsCard } from "../_common";

type CrCardItem = {
    card: {
        id: number,
        invNo: string,
        refOne: string,
        refTwo: string,
        staffNm: string,
        creditor: string,
        authAmmt: number,
        curr: string,
        trans_date: string,
        due: string,
        status: number,
        approved: string,
        synched: string,
        exo_link: string,
        file_name: string
    },
    selected_approve: boolean
}

export class CrCard extends React.Component<CrCardItem> {
    static defaultProps = {
        card: {
            id: -1,
            invNo: '',
            refOne: '',
            refTwo: '',
            staffNm: '',
            creditor: '',
            authAmmt: -1,
            curr: '',
            trans_date: '',
            due: '',
            status: -1,
            approved: '',
            synched: '',
            exo_link: '',
            file_name: 'dummy.pdf'
        },
        selected_approve: false
    }

    state = {
        drill: false,
        onhold: (this.props.card.status === -1),
        canHold: (this.props.card.status < 1),
        approved: (this.props.card.approved === 'Y'),
        approve_timer: '5',
        result: {
            Error: 200,
            Message: 'None',
            Package: false
        },
        hasErrors: false
    };

    render() {
        var { drill } = this.state;
        var { onhold, canHold, approved} = this.state;
        var activeButtons = { approve: true, hold: true, exo: false, details: false, view_doc: true }; // Active Buttons

        if (drill) {
            return <Redirect to={{ pathname: './Approvals/CRA/' + this.props.card.id }} />;
        } else {
            var { card, selected_approve } =  this.props;
            var button_settings = 'col-12 col-lg-3 btn btn-default border AuthCard float-right';

            return (
                <>
                <div id={card.id.toString()}>
                    <div className="row">
                        <div className="col-9 col-lg-10">
                            <div className="row">
                                <div className="col col-6 col-lg-4">
                                    <DetailsCard details={{title: 'Staff Member:', data: card.staffNm, bpad: true}} />
                                </div>
                                <div className="col col-6 col-lg-8">
                                    <DetailsCard details={{title: 'Creditor:', data: card.creditor, bpad: true}} />
                                </div>

                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Invno:', data: card.invNo, bpad: true}} />
                                </div>
                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Ref 1:', data: card.refOne, bpad: false}} />
                                </div>
                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Ref 2:', data: card.refTwo, bpad: false}} />
                                </div>

                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Inv Date:', data: card.trans_date, bpad: false}} />
                                </div>
                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Due Date:', data: card.due, bpad: true}} />
                                </div>
                                <div className="col col-4">
                                    <DetailsCard details={{title: 'Amount:', data: card.authAmmt.toFixed(2) + ' (' + card.curr + ')', bpad: false}} />
                                </div>
                            </div>
                        </div>

                        <div className="d-block d-lg-none col-3">
                            {activeButtons.view_doc && this.props.card.file_name !== '' &&
                            <button className={button_settings} onClick={this.viewFile}>
                                <FontAwesomeIcon className="text-info" icon={faFilePdf} size="lg" fixedWidth /><br />
                                <div className="sub_txt">View File</div>
                            </button>
                            }
                            {activeButtons.details &&
                            <button className={button_settings} onClick={this.details}>
                                <FontAwesomeIcon className="text-info" icon={faClipboardList} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Details</div>
                            </button>
                            }
                            {activeButtons.exo &&
                            <a className={button_settings} href={card.exo_link}>
                                <FontAwesomeIcon className="text-info" icon={faLink} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Exo</div>
                            </a>
                            }
                            {activeButtons.hold &&
                            <button className={button_settings} onClick={this.hold}>
                                <FontAwesomeIcon className={selected_approve && !onhold ? "text-danger" : "text-info"} icon={onhold ? faPlay : faStop} size="lg" fixedWidth /><br />
                                <div className="sub_txt">{onhold ? 'Holding' : 'Hold'}</div>
                            </button>
                            }
                            {activeButtons.approve && !onhold &&
                            <button className={button_settings} onClick={this.approve}>
                                <FontAwesomeIcon className={selected_approve  ? 'text-success' : approved ?  'text-warning' : 'text-info'} icon={faThumbsUp} size="lg" fixedWidth /><br />
                                <div className="sub_txt">{approved ? 'Approved' : 'Approve'}</div>
                            </button>
                            }
                        </div>
                        
                        <div className="d-none d-lg-block col-2 text-right">
                            {activeButtons.approve && !onhold &&
                            <button className={button_settings} onClick={this.approve}>
                                <FontAwesomeIcon className={selected_approve  ? 'text-success' : approved ?  'text-warning' : 'text-info'} icon={faThumbsUp} size="lg" fixedWidth /><br />
                                <div className="sub_txt">{approved ? 'Approved' : 'Approve'}</div>
                            </button>
                            }
                            {activeButtons.hold && canHold &&
                            <button className={button_settings} onClick={this.hold}>
                                <FontAwesomeIcon className={selected_approve && !onhold ? "text-danger" : "text-info"} icon={onhold ? faPlay : faStop} size="lg" fixedWidth /><br />
                                <div className="sub_txt">{onhold ? 'Holding' : 'Hold'}</div>
                            </button>
                            }
                            {activeButtons.exo &&
                            <a className={button_settings} href={card.exo_link}>
                                <FontAwesomeIcon className="text-info" icon={faLink} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Exo</div>
                            </a>
                            }
                            {activeButtons.details &&
                            <button className={button_settings} onClick={this.details}>
                                <FontAwesomeIcon className="text-info" icon={faClipboardList} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Details</div>
                            </button>
                            }
                            {activeButtons.view_doc && this.props.card.file_name !== '' &&
                            <button className={button_settings} onClick={this.viewFile}>
                                <FontAwesomeIcon className="text-info" icon={faFilePdf} size="lg" fixedWidth /><br />
                                <div className="sub_txt">View File</div>
                            </button>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 text-danger">
                            {this.state.hasErrors && <small>Errors</small>}
                        </div>
                    </div>
                </div>
                </>
            )
        }
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10) : false;
    }

    private details = () => {
        if (this.authenticated()) {
            this.setState({ drill: true });
        }
    }

    private viewFile = () => {
        var fileName = this.props.card.file_name;
        var filePath = 'https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/uploadedFiles/'+fileName;
        window.open(filePath, "_blank");
    }

    private hold = () => {
        if (this.authenticated()) {
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/CRA/Hold";
            var payload = {
                Session: sessionStorage.getItem('session'),
                TransList: [{ Id: this.props.card.id }],
                Type: (this.state.onhold) ? "remove-hold" : "hold"
            }
            
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .then(() => {
                this.setState({ onhold: this.state.result.Package!==false });
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private approve = () => {
        if (this.authenticated()) {
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/CRA/Auth";
            var payload = {
                Session: sessionStorage.getItem('session'),
                TransList: [{ Id: this.props.card.id }],
                Type: (this.state.approved) ? "de-auth" : "auth"
            }

            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .then(() => {
                this.setState({ approved: this.state.result.Package!==false });
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }
}

export default CrCard;