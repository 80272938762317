import React, { Component } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import { ProtectedRoute, ProtectedRouteProps } from '../_services';
import { MenuBar } from "../_common";

// TMP APP
import { HomePage } from "../home";
// -- Purchase Orders
import { POAPage, POADetailsPage } from "../po_approvals";
// -- Creditor
import { CRAPage, CRADetailsPage } from "../cr_approvals";
// -- Users
import { USRPage, USRMngPage } from "../user";
// -- Errors
import { NotFoundPage } from "../_errors";

// -- Tests
import { BookPage } from "../book";
import TestArea from "../tests/components/TestArea";

export class Main extends Component {
	render() {
		const defaultProtectedRouteProps: ProtectedRouteProps = {
			isAuthenticated: this.authenticated(),
			authenticationPath: '/login',
        };
        
		return (
			<>
            <div className="App">
                <div className="container col-lg-10 offset-lg-2">
                    <div className='container-fluid'>
                        <Switch>
                            <Route exact path='/Approvals' component={HomePage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} exact path='/Approvals/poa' component={POAPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/poa/:id' component={POADetailsPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} exact path='/Approvals/cra' component={CRAPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/cra/:id' component={CRADetailsPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} exact path='/Approvals/usr' component={USRMngPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/usr/:id' component={USRPage} />

                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/books' component={BookPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/books' component={BookPage} />
                            <ProtectedRoute {...defaultProtectedRouteProps} path='/Approvals/Test' component={TestArea} />
                            <Route component={NotFoundPage} />
                            <Redirect from="*" to="/" />
                        </Switch>
                    </div>
                </div>
            </div>
            <MenuBar />
			</>
    	);
    }
    
    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }
}

export default Main;
