import React from "react";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export class POADetailsPage extends React.Component<any> {
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
            <div className='jumbotron' style={centerStyle}>
                <h3>PO Details</h3>
            </div>
            </>
        );
    }
}

export default POADetailsPage;