import React from "react";
import AuthList from "../auth_list/AuthList";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export class POAPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
            <div className='jumbotron' style={centerStyle}>
                <h3>PO Approval</h3><br />
            </div>
            <AuthList type="PO" />
            </>
        );
    }
}

export default POAPage;