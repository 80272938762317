import React from "react";

type DeatilsBlockItem = {
    details: {
        title: string,
        data: string,
        bpad: boolean
    }
}

export class DetailsCard extends React.Component<DeatilsBlockItem> {
    static defaultProps = {
        details: {
            title: '',
            data: '',
            bpad: false
        }
    }

    render() {
        var bottomspace = (this.props.details.bpad) ? '0pt' : '4px';

        return (
            <>
            <div className="title" style={{display: 'inline-block', width: '100%', maxWidth: '240px'}}>{this.props.details.title}</div>
            { (this.props.details.data.length > 0) ?
                <div style={{marginBottom: bottomspace}}>{(this.props.details.data)}</div>:
                <div className="badge badge-pill badge-light" style={{marginBottom: bottomspace, display: 'inline-block', width: '100%', maxWidth: '240px'}}>&emsp;</div>
            }
           </>
        )
    }
}

export default DetailsCard;