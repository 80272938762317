import React from "react";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}


export class AboutPage extends React.Component {
    render() {
        return  (
            <>
            <div className='container-fluid'>
                <div className="jumbotron">
                    <p style={centerStyle}>The application is currrently in development.</p>
                    <p style={centerStyle}>This applications main objective is to provide a scalable portal for useful processes such as "Purchase Order Approval" and "Creditor Approval".</p>
                </div>
            </div>
            </>
        )
    }
};

export default AboutPage;