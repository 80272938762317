import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import './Header.css';

type IsAuthed = {
    authorised: boolean
}

export class Header extends React.Component<IsAuthed> {
    static defaultProps = {
        authorised: false
    }

    state = {
        IsAuthed: this.props.authorised
    }

    render () {
        const activeStyle = { color: '#77C777', textShadow: '0px 0px 1px #FAFAFA' }
        return  (
            <>
                <nav className='header'>
                    { this.state.IsAuthed || this.authenticated() ?
                        <><NavLink to='/Approvals' activeStyle={activeStyle} exact><FontAwesomeIcon icon={faHome} fixedWidth /></NavLink></>:
                        <><NavLink to='/' activeStyle={activeStyle} exact><FontAwesomeIcon icon={faHome} fixedWidth /></NavLink></>
                    }

                    { this.state.IsAuthed || this.authenticated()  ?
                        <>{ " | " }<NavLink to='#' style={{color: 'white', textTransform: 'capitalize'}} >{sessionStorage.getItem('userName')}</NavLink></>:
                        <>{ " | " }<NavLink to='/About' activeStyle={activeStyle} >About </NavLink></>
                    }

                    { this.state.IsAuthed || this.authenticated()  ?
                        <>{ " | " }<NavLink to='/Logout' activeStyle={activeStyle} style={{textTransform: 'capitalize'}}>Logout </NavLink></>:
                        <>{ " | " }<NavLink to='/Login' activeStyle={activeStyle} style={{textTransform: 'capitalize'}}>Login </NavLink></>
                    }
                </nav>
            </>
        )
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }
};

export default Header;