import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faThumbsUp, faStop, faSearch, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';
import './AuthList.css';

import { CrCard } from '../cr_approvals/';
import { PoCard } from '../po_approvals/';

type AuthType = {
    type: string
}

type TransList = {
    Id: number
}

type PoResponse = {
    staff_no: number,
    staff_name: string,
    staff_email: string,
    reports_to_no: number,
    creditor: string,
    trans_seqno: string,
    trans_date: string,
    trans_due_date: string,
    trans_inv_no: string,
    trans_reference: string,
    trans_reference2: string,
    trans_amount: string,
    curr_code: string,
    lcl_amount: number,
    pay_status: string,
    approved: string,
    synched: string,
    exo_link: number,
    file_name: string,
    selected: boolean
}

type CrResponse = {
    staff_no: number,
    staff_name: string,
    staff_email: string,
    reports_to_no: number,
    creditor: string,
    trans_seqno: string,
    trans_date: string,
    trans_due_date: string,
    trans_inv_no: string,
    trans_reference: string,
    trans_reference2: string,
    trans_amount: string,
    curr_code: string,
    lcl_amount: number,
    pay_status: string,
    approved: string,
    synched: string,
    exo_link: number,
    file_name: string,
    selected: boolean
}

type ResponseHandle = {
    Cr?: CrResponse,
    Po?: PoResponse
}

export class AuthList extends React.Component<AuthType> {
    static defaultProps = {
        type: 'Missing'
    }

    state = {
        result: {
            Error: 100,
            Message: 'Success',
            Package: [{
                staff_no: -1,
                staff_name: "",
                staff_email: "",
                reports_to_no: 1,
                creditor: "",
                trans_seqno: -1,
                trans_date: "",
                trans_due_date: "",
                trans_inv_no: "",
                trans_reference: "",
                trans_reference2: "",
                trans_amount: -1,
                curr_code: "",
                lcl_amount: -1,
                pay_status: 1,
                approved: 'N',
                synched: 'N',
                exo_link: '',
                file_name: '',
                selected: false
            }],
            Item_Count: 1
        },
        transList: [{
            Id: -1
        }],
        select_all_approve: false,
        hasErrors: false,
        search: {
            Search1: '',
            Search2: ''
        },
        paging: {
            Number: 1,
            Limit: 10,
            pageMin: 1,
            pageMax: 1
        },
        maxPages: 1
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.retrieve_auth_list();
    }

    render() {
        var { paging, result, search} = this.state;

        var search_bar =
            <>
                {(this.props.type === 'CR' || this.props.type === 'PO') && 
                <>
                <div className="col-12 col-lg-4">
                    <div className="input-group">
                        <input id="Search1" type="text" className="form-control text-muted" placeholder={this.props.type === 'CR' ? "INVNO" : "REFERENCE"} aria-label={this.props.type === 'CR' ? "SearchINVNO" : "SearchREFERENCE"} aria-describedby="basic-addon1" onKeyUp={this.add_search} />
                        <input id="Search2" type="text" className="form-control text-muted" placeholder={this.props.type === 'CR' ? "CREDITOR/STAFF" : "CREDITOR/STAFF"} aria-label={this.props.type === 'CR' ? "SearchCREDITOR" : "SearchCREDITOR"} aria-describedby="basic-addon1" onKeyUp={this.add_search} />
                        <div className="input-group-append" onClick={this.clear_search}>
                            <span id="basic-addon1" className={(search.Search1.length > 0 || search.Search2.length > 0) ? "input-group-text" : "input-group-text"}><FontAwesomeIcon icon={faTimes} fixedWidth /></span>
                        </div>
                        <div className="input-group-append" onClick={this.search_value}>
                            <span id="basic-addon1" className={(search.Search1.length > 0 || search.Search2.length > 0) ? "input-group-text text-white" : "input-group-text"} style={(search.Search1.length > 0 || search.Search2.length > 0) ? {background: "#77C777"} : {}}><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </div>
                    </div>
                </div>
                </>
                }
            </>
        ;
        var pageSelect=[];
        for(var k = paging.pageMin; k <= paging.pageMax; k++) {
            if(paging.Number === k)  {
                pageSelect.push(<li key={'pg_'+k.toString()} className="page-item"><button className="page-link text-white" style={{background: "#77C777"}}>{k}</button></li>);
            } else {
                pageSelect.push(<li key={'pg_'+k.toString()} className="page-item"><button className="page-link text-muted" onClick={this.setPage} value={k}>{k}</button></li>);
            }
        }
        var pagenation = 
            <>
                <nav aria-label="col-12 Page navigation">
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <button className="page-link text-muted" onClick={this.setPage} value={paging.Number-1} aria-label="Previous">
                                <span aria-hidden="true">&laquo;</span>
                                <span className="sr-only">Previous</span>
                            </button>
                        </li>
                        {pageSelect}
                        <li className="page-item">
                            <button className="page-link text-muted" onClick={this.setPage} value={paging.Number+1} aria-label="Next">
                                <span aria-hidden="true">&raquo;</span>
                                <span className="sr-only">Next</span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </>
        ;
        var button_right_settings = 'col-3 col-lg-1 btn btn-sm btn-default border AuthCard float-right';
        var button_left_settings  = 'col-3 col-lg-1 btn btn-sm btn-default border AuthCard float-left';
        var toolbar = 
            <>
                <li className="list-group-item">
                    <div className="row">
                        <div className="col-12">
                            <button className={button_right_settings} onClick={this.toggleSelectAll}>
                                <FontAwesomeIcon className="text-info" icon={faPlus} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Select <br />All</div>
                            </button>
                            <button className={button_right_settings} onClick={this.toggleToggleSelected}>
                                <FontAwesomeIcon className="text-info" icon={faPlus} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Invert <br />Selected</div>
                            </button>
                            {this.state.select_all_approve &&
                            <button className={button_left_settings} onClick={this.approveSelected}>
                                <FontAwesomeIcon className="text-success" icon={faThumbsUp} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Approve <br />Selected</div>
                            </button>
                            }
                            {this.state.select_all_approve && this.props.type === 'CR' &&
                            <button className={button_left_settings} onClick={this.holdSelected}>
                                <FontAwesomeIcon className="text-danger" icon={faStop} size="lg" fixedWidth /><br />
                                <div className="sub_txt">Hold <br />Selected</div>
                            </button>
                            }
                        </div>
                    </div>
                </li>
            </>
        ;
        
        var elements = [];
        if (this.props.type === 'CR') {
            for (var c_i = 0; c_i < result.Package.length; c_i++) {
                if (result.Package[c_i].staff_no !== -1) {
                    // push the component to elements!

                    var cr_item = {
                        id: result.Package[c_i].trans_seqno,
                        invNo: result.Package[c_i].trans_inv_no,
                        refOne: result.Package[c_i].trans_reference,
                        refTwo: result.Package[c_i].trans_reference2,
                        staffNm: result.Package[c_i].staff_name,
                        creditor: result.Package[c_i].creditor,
                        authAmmt: result.Package[c_i].lcl_amount,
                        curr: result.Package[c_i].curr_code,
                        trans_date: result.Package[c_i].trans_date,
                        due: result.Package[c_i].trans_due_date,
                        status: result.Package[c_i].pay_status,
                        approved: result.Package[c_i].approved,
                        synched: result.Package[c_i].synched,
                        exo_link: result.Package[c_i].exo_link,
                        selected: result.Package[c_i].selected,
                        file_name: result.Package[c_i].file_name
                    };
                    elements.push(
                        <>
                            {/*
                            <li key={'cr_h_'+cr_item.id} className={cr_item.selected ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-light'}>
                                <button className="col-3 col-lg-1 btn btn-sm btn-light border AuthCard" onClick={this.handleClickSingleSelect} value={cr_item.id}>
                                    <FontAwesomeIcon className={cr_item.selected ? 'text-success' : 'text-info'} icon={cr_item.selected ? faCheck : faTimes} size="sm" fixedWidth />
                                    <br />
                                    <div className="sub_txt">{cr_item.selected ? 'Un-Select' : 'Select'}</div>
                                </button>
                            </li>
                            */}
                            <li key={'cr_h_'+cr_item.id} className="list-group-item list-group-item-light">
                                <div className="col-12 col-lg-1 float-left">
                                    <button className="btn btn-sm btn-default AuthCard" onClick={this.handleClickSingleSelect} value={cr_item.id}>
                                        <FontAwesomeIcon className={cr_item.selected ? 'text-success' : 'text-info'} icon={cr_item.selected ? faCheckSquare : faSquare} size="lg" fixedWidth />
                                        {/* <div className="sub_txt">{cr_item.selected ? 'Un-Select' : 'Select'}</div> */}
                                    </button>
                                </div>
                                <div className="col-12 col-lg-11 float-right"><CrCard key={'cr_'+cr_item.id} card={cr_item} selected_approve={cr_item.selected} /></div>
                            </li>
                            <small>&nbsp;</small>
                        </>
                    );
                } else {
                    elements.push(<li key={'cr_err'} className="list-group-item"><div className="row">None Available</div></li>);
                }
            }
            return (
                <>
                <ul className="list-group">
                    {search_bar}<small>&nbsp;</small>
                    {pagenation}<small>&nbsp;</small>
                    {toolbar}<small>&nbsp;</small>
                    {elements}
                    {this.state.hasErrors && <li className="list-group-item"><div className="row text-red">Errors</div></li>}
                    {toolbar}<small>&nbsp;</small>
                    {pagenation}
                </ul>
                </>
            );
        } else if (this.props.type === 'PO') {
            for (var p_i = 0; p_i < result.Package.length; p_i++) {
                if (result.Package[p_i].staff_no !== -1) {
                    // push the component to elements!
                    var po_item = {
                        id: result.Package[p_i].trans_seqno,
                        refOne: result.Package[p_i].trans_reference,
                        staffNm: result.Package[p_i].staff_name,
                        creditor: result.Package[p_i].creditor,
                        authAmmt: result.Package[p_i].lcl_amount,
                        curr: result.Package[p_i].curr_code,
                        trans_date: result.Package[p_i].trans_date,
                        due: result.Package[p_i].trans_due_date,
                        approved: result.Package[p_i].approved,
                        synched: result.Package[p_i].synched,
                        exo_link: result.Package[p_i].exo_link,
                        selected: result.Package[p_i].selected,
                        file_name: result.Package[p_i].file_name
                    };
                    elements.push(
                        <>
                            {/* 
                            <li key={'po_h_'+po_item.id} className={po_item.selected ? 'list-group-item list-group-item-success' : 'list-group-item list-group-item-light'}>
                                <button className="col-2 col-lg-1 btn btn-sm btn-light border AuthCard" onClick={this.handleClickSingleSelect} value={po_item.id}>
                                    <FontAwesomeIcon className={po_item.selected ? 'text-success' : 'text-info'} icon={po_item.selected ? faCheck : faTimes} size="sm" fixedWidth />
                                    <br />
                                    <div className="sub_txt">{po_item.selected ? 'Un-Selected' : 'Select'}</div>
                                </button>
                            </li>
                            <PoCard key={'po_'+po_item.id} card={po_item} selected_approve={po_item.selected} />
                            */}
                            <li key={'cr_h_'+po_item.id} className="list-group-item list-group-item-light">
                                <div className="col-12 col-lg-1 float-left">
                                    <button className="btn btn-sm btn-default AuthCard" onClick={this.handleClickSingleSelect} value={po_item.id}>
                                        <FontAwesomeIcon className={po_item.selected ? 'text-success' : 'text-info'} icon={po_item.selected ? faCheckSquare : faSquare} size="lg" fixedWidth />
                                        {/* <div className="sub_txt">{po_item.selected ? 'Un-Select' : 'Select'}</div> */}
                                    </button>
                                </div>
                                <div className="col-12 col-lg-11 float-right"><PoCard key={'cr_'+po_item.id} card={po_item} selected_approve={po_item.selected} /></div>
                            </li>
                            <small>&nbsp;</small>
                        </>
                    );
                } else {
                    elements.push(<li key={'po_err'} className="list-group-item"><div className="row">None Available</div></li>);
                }
            }
            return (
                <>
                <ul className="list-group">
                    {search_bar}<small>&nbsp;</small>
                    {pagenation}<small>&nbsp;</small>
                    {toolbar}<small>&nbsp;</small>
                    {elements}
                    {this.state.hasErrors && <li className="list-group-item"><div className="row text-red">Errors</div></li>}
                    {toolbar}<small>&nbsp;</small>
                    {pagenation}
                </ul>
                </>
            );
        }
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10) : false;
    }

    private setPage = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        var selectedValue = Number(e.currentTarget.value);
        var { maxPages } = this.state;

        var pageNo = Math.max(selectedValue, 1);
        pageNo = Math.min(pageNo, maxPages);
        
        //this.setState({ paging: {Number: pageNo, Limit: this.state.paging.Limit}});
        this.setPaginationRange(pageNo, this.state.paging.Limit);
        window.scrollTo(0, 0);
        this.retrieve_auth_list(pageNo, this.state.paging.Limit);
    }
    
    private setPaginationRange = (pageNumber: number = this.state.paging.Number, pageLimit: number = this.state.paging.Limit) => {
        var { paging, maxPages } = this.state;
        var barSize = 3;
        if (pageNumber <= barSize) {
            paging.pageMin = 1;
            paging.pageMax = (barSize * 2);
        }

        if ((pageNumber >= (barSize)) && (pageNumber <= (maxPages - (barSize)))) {
            paging.pageMin = pageNumber - (barSize);
            paging.pageMax = pageNumber + barSize;
        }

        if (pageNumber >= (maxPages - barSize)) {
            paging.pageMin = maxPages - (barSize * 2);
            paging.pageMax = maxPages;
        }
        paging.pageMin = Math.max(1, paging.pageMin);
        this.setState({paging: { Number: pageNumber, Limit: pageLimit, pageMin : paging.pageMin, pageMax: paging.pageMax }});
    }  

    private toggleSelectAll = () => {
        const new_package = this.state.result.Package;
        let new_transList: TransList[] = [];
        var selectedCount = 0;
        var unselectedCount = 0;
        var selType = true; // Select All = true, delsect All = false

        new_package.forEach(inner_item => {
            if(inner_item.selected) { selectedCount++; } else { unselectedCount++; }
        });

        if(selectedCount === 0) {
            selType = true;
        } else if (unselectedCount === 0) {
            selType = false;
        } else {
            selType = (selectedCount<unselectedCount);
        }

        new_package.forEach(inner_item => {
            inner_item.selected = selType;
            new_transList.push({ Id: inner_item.trans_seqno });
        });

        this.setState({transList: new_transList});
        this.setState({Package: new_package});
    }

    private toggleToggleSelected = () => {
        const new_package = this.state.result.Package;
        let new_transList: TransList[] = [];
        var selectedCount = 0;

        new_package.forEach(inner_item => {
            inner_item.selected = !inner_item.selected;
            if(inner_item.selected) {
                new_transList.push({ Id: inner_item.trans_seqno });
                selectedCount++;
            }
        });

        this.setState({select_all_approve: (selectedCount > 0)});
        this.setState({transList: new_transList});
        this.setState({Package: new_package});
    }

    private toggleSelectSingle = (id: number) => {
        const new_package = this.state.result.Package;
        let new_transList: TransList[] = [];
        var selectedCount = 0;

        new_package.forEach(inner_item => {
            if(inner_item.trans_seqno === id) {
                inner_item.selected = !inner_item.selected;
            }
            if(inner_item.selected) {
                new_transList.push({ Id: inner_item.trans_seqno });
                selectedCount++;
            }
        });

        this.setState({select_all_approve: (selectedCount > 0)});
        this.setState({transList: new_transList});
        this.setState({Package: new_package});
    }

    private handleClickSingleSelect = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        var selectedValue = e.currentTarget.value;
        this.toggleSelectSingle(parseInt(selectedValue));
    }

    private holdSelected = () => {
        // Sent Post to Update API with approved items
        if (this.authenticated()) {
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/" + this.props.type + "A/Hold";
            var payload = {
                Session: sessionStorage.getItem('session'),
                TransList: this.state.transList,
                Type: 'hold'
            }

            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                if(res.Error === 200) { window.location.reload(false); } else { alert(res.Message); }
            })
            .then(() => {
                window.location.reload(false);
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private approveSelected = () => {
        // Sent Post to Update API with approved items
        if (this.authenticated()) {
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/" + this.props.type + "A/Auth";
            var payload = {
                Session: sessionStorage.getItem('session'),
                TransList: this.state.transList,
                Type: 'auth'
            }

            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                if(res.Error === 200) { window.location.reload(false); } else { alert(res.Message); }
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private clear_search = () => {
        if (this.authenticated()) {
            const search1_value = document.getElementById("Search1") as HTMLInputElement;
            const search2_value = document.getElementById("Search2") as HTMLInputElement;
            search1_value.value = '';
            search2_value.value = '';

            this.setState({
                search: {
                    Search1: '', 
                    Search2: ''
                }
            });
        }
    }

    private add_search = (e: React.KeyboardEvent<any>) => {
        e.preventDefault();
        const search1_value = document.getElementById("Search1") as HTMLInputElement;
        const search2_value = document.getElementById("Search2") as HTMLInputElement;
        if (this.authenticated()) {
            if ( e.key === 'Enter' ){
                this.search_value();
            } else {
                this.setState({
                    search: {
                        Search1: search1_value.value, 
                        Search2: search2_value.value
                    }
                });
            }
        }
    }

    private search_value = () => {
        this.retrieve_auth_list();
    }

    private retrieve_auth_list = (number: number = this.state.paging.Number, limit: number = this.state.paging.Limit) => {
        if (this.authenticated()) {
            var paging = {Number: number, Limit: limit}
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/" + this.props.type + "A/List";
            var payload = {
                Session: sessionStorage.getItem('session'),
                Search: this.state.search,
                Paging: paging
            }

            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .then(() => {
                this.setState({ select_all_approve: false });
            })
            .then(() => {
                this.setState({ maxPages: Math.ceil(Math.max(1, this.state.result.Item_Count)/Math.max(1, this.state.paging.Limit)) });
            })
            .then(() => {
                this.setPaginationRange();
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }
}

export default AuthList;