import React, { Component } from 'react';
import { Switch, Route, Redirect } from "react-router-dom";
import './App.css';

// components
import { Header, Footer } from "./components/_common";
import { ProtectedRoute, ProtectedRouteProps } from './components/_services';
import IdleTimer from 'react-idle-timer';

//--Pages
import { Main } from "./components/main";
import { HomePage } from "./components/home";
import { AboutPage } from "./components/about";
import { LoginPage, Logout } from "./components/login";
import { NotFoundPage } from "./components/_errors/NotFoundPage";

// Styling
import 'bootstrap/dist/css/bootstrap.min.css';

interface Props {
	
}

class App extends Component {
	private idleTimer: any;
	private onAction: any;
	private onActive: any;
	private onIdle: any;

	state = {
		timeout:1000 * 60 * 60 * 2, // 2  hours
		refresh:1000 * 60 * 10, 	// 10 minutes
		showModal: false,
		userLoggedIn: false,
		isTimedOut: false,
		hasErrors: false,
		response: { Error: 100, Message: '', Modules: { poa: 'false', cra: 'false'} }
	};

	constructor(props: Props) {
		super(props);

		this.idleTimer = null;
		this.onAction = this._onAction.bind(this);
		this.onActive = this._onActive.bind(this);
		this.onIdle = this._onIdle.bind(this);
	}

	render() {
		const defaultProtectedRouteProps: ProtectedRouteProps = {
			isAuthenticated: this.authenticated(),
			authenticationPath: '/login',
		};

		const { isTimedOut, userLoggedIn } = this.state;
		if(isTimedOut) {
            return <Redirect to={{pathname: '/'}}/>;
        } else {
			return (
				<>
				<IdleTimer ref={ref => { this.idleTimer = ref }} element={document} onActive={this.onActive} onIdle={this.onIdle} onAction={this.onAction} debounce={250} timeout={this.state.timeout} />
				<IdleTimer ref={ref => { this.idleTimer = ref }} element={document} onActive={this._onActiveRefresh} onIdle={this.onIdle} onAction={this.onAction} debounce={250} timeout={this.state.timeout} />
				<Header authorised={userLoggedIn} />
				<div className="App">
					<Switch>
						<Route exact path='/' component={HomePage} />
						<Route path='/about' component={AboutPage} />
						<Route path='/login' render={(props) => <LoginPage {...props} authCallback={this.IsAuthed} />} />
						<Route path='/logout' render={(props) => <Logout {...props} authCallback={this.IsAuthed} />} />
						<ProtectedRoute path='/Approvals' component={Main} {...defaultProtectedRouteProps} />
						<Route component={NotFoundPage} />
						<Redirect from="*" to="/" />
					</Switch>
				</div>
				<Footer authorised={userLoggedIn} />
				</>
			);
		}
	}

	private IsAuthed = (AuthResponse: boolean) => {
		this.setState({ userLoggedIn: AuthResponse });
	}

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }

	private logout = () => {
		if (!this.authenticated()) {
			const Token = sessionStorage.getItem('session');
			var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/Logout";
			var payload = {
				Usr: Token
			}

			fetch(tmp_url, {
				method: "POST",
				body: JSON.stringify(payload)
			})
			.then(res => res.json())
			.then(() => {
				sessionStorage.removeItem('session');
				sessionStorage.removeItem('userName');
				sessionStorage.removeItem('poa');
				sessionStorage.removeItem('cra');
				this.setState({ isTimedOut: true });
			})
			//.then(() => window.location.reload(false))
			.catch(() => this.setState({ hasErrors: true }));
		} else {
			this.setState({ isTimedOut: true });
			sessionStorage.removeItem('session');
			sessionStorage.removeItem('userName');
            sessionStorage.removeItem('poa');
            sessionStorage.removeItem('cra');
			//window.location.reload(false);
		}
	}

	_onAction(e: any) {
		this.setState({ isTimedOut: false });
	}

	_onActionRefresh(e: any) {
		this.setState({ isTimedOut: false });
	}

	_onActive(e: any) {
		this.setState({ isTimedOut: false });
	}

	_onActiveRefresh(e: any) {
		this.setState({ isTimedOut: false });
	}

	_onIdle(e: any) {
		this.logout();
	}

	_onIdleRefresh(e: any) {
		this.logout();
	}
}

export default App
