import React, {FunctionComponent} from 'react';

var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export const NotFoundPage:FunctionComponent = () => <div className='container-fluid'><div className="row"><p>&nbsp;</p></div><div style={centerStyle}>Oops Page Not Found</div></div>;

export default NotFoundPage;