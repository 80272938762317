import React from "react";
import AuthList from "../auth_list/AuthList";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

export class CRAPage extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
            <div className='jumbotron' style={centerStyle}>
                <h3>CR Approval</h3>
            </div>
            <AuthList type="CR" />
            </>
        );
    }
}

export default CRAPage;