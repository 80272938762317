import React from "react";

// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

type CrId = {
    id: number,
}

export class CRADetailsPage extends React.Component<CrId> {
    static defaultProps = {
        id: -1
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <>
            <div className='jumbotron' style={centerStyle}>
                <h3>CR Details: ({this.props.id})</h3>
            </div>
            </>
        );
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }
}

export default CRADetailsPage;