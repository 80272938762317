import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLock, faAt, faCloud, faSave, faPlus, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquare } from '@fortawesome/free-regular-svg-icons';


// Style Declarations
var centerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
}

type UserId = {
    id: number,
    match: any
}

export class USRPage extends Component<UserId> {
    state = {
        id: this.props.match.params.id,
        result : {
            Error: 100,
            Message: 'Success',
            Package: [{ 
                id: -1,
                name: "None Found",
                email: "example@domain.com",
                password: "",
                modules: { poa: false, cra: false },
                has_login: false,
                active: false,
                admin_view: false
            }]
        }
    };

    componentDidMount() {
        window.scrollTo(0, 0);
        this.retrieve_user();
    }

    render() {
        var user = this.state.result.Package[0];
        return (
            <>
                <div className='jumbotron' style={centerStyle}>
                    <h3>User Settings</h3>
                </div>
                <ul className="list-group">
                    <li className="list-group-item">
                        <div className="col-10 col-lg-11">{user.name}</div>
                        <div className="col-12">
                            <div className={(user.active) ? "text-success" : "text-muted"}><FontAwesomeIcon icon={faUser} fixedWidth /> {(user.active) ? "Active Staff" : "Inactive Staff"}</div>
                            <div className={(user.has_login) ? "text-success" : "text-muted"}><FontAwesomeIcon icon={faCloud} fixedWidth /> {(user.has_login) ? "Has Login" : "No Login"}</div>
                        </div>
                        <small>&nbsp;</small>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-muted" id="basic-addon1"><FontAwesomeIcon icon={faAt} fixedWidth /></span>
                            </div>
                            <input id="emailInput" type="text" className="form-control" placeholder="Please add e-mail to Staff in Exo" aria-label="User" aria-describedby="basic-addon1" value={user.email} onKeyUp={this.user_change} readOnly />
                        </div>
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-text text-muted" id="basic-addon1"><FontAwesomeIcon icon={faLock} fixedWidth /></span>
                            </div>
                            <input id="passwordInput" type="password" className="form-control" placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" onKeyUp={this.user_change} />
                        </div>
                        <small className="text-muted">Note: Users are only able to set passwords</small><br />
                        <br />

                        {user.admin_view && <div className="col-12">
                            <button className="btn btn-sm btn-default AuthCard" onClick={this.togglePOA}>
                                <FontAwesomeIcon className="text-info" icon={user.modules.poa ? faCheckSquare : faSquare } size="lg" fixedWidth />
                            </button>
                            {user.modules.poa ? 'Allow PO Approval' : 'Allow PO Approval'}
                            <br />
                            <button className="btn btn-sm btn-default AuthCard" onClick={this.toggleCRA}>
                                <FontAwesomeIcon className="text-info" icon={user.modules.cra ? faCheckSquare : faSquare} size="lg" fixedWidth />
                            </button>
                            {user.modules.cra ? 'Allow CR Approval' : 'Allow CR Approval'}
                            <br />
                            <br />
                        </div>
                        }

                        {!user.has_login && user.email !== '' &&
                        <button className="col-12 btn btn-sm btn-default border AuthCard" onClick={this.add_user}>
                            <FontAwesomeIcon className="text-info" icon={faPlus} size="2x" fixedWidth /><br />
                            <div className="sub_txt">Activate User Login</div>
                        </button>
                        }
                        {!user.has_login && user.email === '' &&
                        <button className="col-12 btn btn-sm btn-default border" disabled>
                            <FontAwesomeIcon className="" icon={faPlus} size="2x" fixedWidth /><br />
                            <div className="sub_txt">Not Available</div>
                        </button>
                        }
                        {user.has_login &&
                        <button className="col-12 btn btn-sm btn-default border AuthCard" onClick={this.edit_user}>
                            <FontAwesomeIcon className="text-info" icon={faSave} size="2x" fixedWidth /><br />
                            <div className="sub_txt">Save User Settings</div>
                        </button>
                        }
                    </li>
                </ul>
            </>
        );
    }

    private authenticated = () => {
        return (sessionStorage.getItem('session') !== null) ? (sessionStorage.session.length > 10)  : false;
    }

    private togglePOA = () => {
        if(this.authenticated()) {
            var { modules } = this.state.result.Package[0];
            modules.poa = !modules.poa;

            this.setState({ modules: modules });
        }
    }

    private toggleCRA = () => {
        if(this.authenticated()) {
            var { modules } = this.state.result.Package[0];
            modules.cra = !modules.cra;

            this.setState({ modules: modules });
        }
    }
    
    private user_change = (e: React.KeyboardEvent<any>) => {
        e.preventDefault();
        if (this.authenticated()) {
            var newPackage = this.state.result.Package[0];
        
            const emailInput = document.getElementById("emailInput") as HTMLInputElement;
            const passwordInput = document.getElementById("passwordInput") as HTMLInputElement;

            if ( e.key === 'Enter' ){
                
            } else {
                newPackage.email    = emailInput.value;
                newPackage.password = passwordInput.value;
                this.setState({ Package: newPackage });
            }
        }
    }

    private retrieve_user = () => {
        if(this.authenticated()) {
            const { match: { params } } = this.props;
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/USR/Slct";
            var payload = {
                Session: sessionStorage.getItem('session'),
                UserId: params.id
            }
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private edit_user = () => {
        if(this.authenticated()) {
            const { match: { params } } = this.props;
            var settings = this.state.result.Package[0];
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/USR/Edit";
            var payload = {
                Session: sessionStorage.getItem('session'),
                UserId: params.id,
                Package: settings
            }
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }

    private add_user = () => {
        if(this.authenticated()) {
            const { match: { params } } = this.props;
            var settings = this.state.result.Package[0];
            var tmp_url = "https://optimiseyourbusiness.co.nz/Approvals/ApprovalSystem/USR/Add";
            var payload = {
                Session: sessionStorage.getItem('session'),
                UserId: params.id,
                Package: settings
            }
            fetch(tmp_url, {
                method: "POST",
                body: JSON.stringify(payload)
            })
            .then(res => res.json())
            .then(res => {
                this.setState({ result: res });
            })
            .catch(() => this.setState({ hasErrors: true }));
        }
    }
}

export default USRPage;